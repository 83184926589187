.faq-position {
  position: absolute;
  width: 100%;
  height: 1150px;
  display: block;
  top: 0;
  left: 0;
  right: 1;
  bottom: 0;
}
.faq-box {
  color: white;
  /* background-color: #222222; */
  z-index: 1000;
  width: 45.8%;
  border: #69503b 3px double;
  margin-top: -5px;
  margin-left: 557px;
}

.faq-content {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 20px;
  color: #fefefedb;
}
