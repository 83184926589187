.App-footer {
  position: absolute;
  width: 100%;
  background-image: url('./Footer_images/bg_footer.jpg');
  height: 220px;
  color: white;
  z-index: 10000;
  display: block;
}

.footer-blur {
  height: 250px;
  z-index: 9999;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  content: '';
  display: block;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
