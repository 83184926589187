.App-header {
  position: absolute;
  width: 100%;
  background-image: url('./Header_images/header-menu.png');
  height: 108px;
  color: white;
  z-index: 10000;
  display: block;
  margin-top: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-blur {
  height: 85px;
  z-index: 9999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  margin-top: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.header-nav {
  width: 50%;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
}

.nav-item {
  float: left;
  text-align: center;
  padding: 10px 0;
  width: 33%;
}
.nav-item:nth-child(even) {
  margin-top: 0px;
}

.nav-item:nth-child(odd):hover {
  color: #8bf1ff;
  text-decoration: underline;
}

.header-block {
  text-align: center;
  width: 100%;
  height: 75px;
}

.header-logo {
  padding-top: 200px;
  height: 150px;
  width: auto;
  position: absolute;
  color: #222222;
  z-index: 10001;
  margin-left: auto;
  margin-right: auto;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-video {
  height: 600px;
  width: 100%;
  background-color: #222222;
  z-index: 100;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

video {
  object-fit: fill;
}
