html,
body {
  margin: 0;
  background-color: #111111;
  z-index: -1;
}

h3 {
  text-align: center;
}

h1 {
  margin: 0;
}

input {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}
.App {
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.space-from-header {
  padding-top: 100px;
}

.homepage-body {
  margin-top: 675px;
  width: 100%;
  height: 1400px;
  color: #ffffff;
}

.background-site {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px;
  background-repeat: no-repeat;
  background-color: #111111;
  z-index: 0;
  position: fixed;
}

.homepage-body-content {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 100;
}
